import React from "react";
import "./PageInternships.css";

export default class PageInternships extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	// eslint-disable-next-line class-methods-use-this
	render() {
		return (
			<div id={"PageInternships"}>
			</div>
		);
	}
}
